.header {
  font-family: Arial, sans-serif;
}

.header-top {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #e0e0e0;
  height:10vh
}

.contact-info span {
  margin-right: 15px;
}
.contact-info{
  background-color: #f9f9f9 !important;
}

.additional-info {
  display: flex;
  align-items: center;
}

.social-icons {
  margin-left: 15px;
}

.social-icons a {
  color: #555;
  margin-right: 12px;
  text-decoration: none;
}

.social-icons a:hover {
  color: #000;
}

.header-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  height: 18vh;
  background-color: white;
 
}
.logo-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust the gap to control the space between the logo and the text */
}

.logo img {
  height: 100%;
  animation: changeColor 5s infinite alternate;
}

.company-name {
  font-size: 16px; /* Adjust the font size to fit your design */
  font-weight: 700;
  margin-top: -25px;
  padding: 0;
  line-height: 1;
  color: #020e28;

}

.logo {
  overflow: hidden;
  position: relative;
  height: 120px;
  animation: rotateLogo 5s infinite linear, changeSize 5s infinite alternate;
}

.logo img {
  height: 100%;
  animation: changeColor 5s infinite alternate; 
}

@keyframes rotateLogo {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes changeColor {
  0% {
    filter: hue-rotate(0deg); 
  }
  33% {
    filter: hue-rotate(60deg); 
  }
  66% {
    filter: hue-rotate(240deg);
  }
  100% {
    filter: hue-rotate(0deg); 
  }
}

@keyframes changeSize {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1.2);
  }
}

.nav {
  display: flex;
  align-items: center;
}

.nav ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav ul li {
  margin: 0 15px;
}
.nav ul li a {
  text-decoration: none;
  color: #000;
}
.nav ul li a:hover {
  color: #ff4b4b;
  cursor: pointer;
  font-size: 1.2em;
  border-bottom: solid 2px;
}

.nav-actions {
  display: none;
}

.header-actions {
  display: flex;
  align-items: center;
}

.login, .signup, .loginn, .signuup{
  background-color: #ff4b4b;
  color: white;
  border: none;
  padding: 5px 15px;
  cursor: pointer;
  margin-right: 10px;
  height: 40px;
  width: 80px;
 
}

.header-btn a {
  background-color: #ff4b4b !important;
  color: white !important;
  border: none;
  padding: 5px 15px;
  cursor: pointer;
  margin-right: 10px;
  height: 40px;
  width: 80px;
}

/* .header-btn a {
  background-color: #ffcccc;

} */

/* .login:hover, .signup:hover, .loginn:hover, .signuup:hover{
  background-color: #ffcccc;
} */

.hamburger {
  display: none;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}


.services-dropdown {
  position: relative;
}

.services-dropdown .dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 2;
}

.services-dropdown:hover .dropdown,
.services-dropdown .dropdown.show {
  display: block;
}

.services-dropdown .dropdown li {
  list-style: none;
  margin: 5px 0;
  border-bottom: solid 1px grey;
}

.services-dropdown .dropdown li a {
  color: #000;
  text-decoration: none;
  padding: 5px 10px;
  display: block;
  font-size: .8em;
}

.services-dropdown .dropdown li a:hover {
  background-color: #f0f0f0;
  color: #ff4b4b;
  border-radius: 4px;
  font-size: 1em;
}

@media (max-width: 1215px) {
  .nav {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    background-color: white;
    z-index: 1000;
  }

  .header-bottom {
    border-bottom: 1px solid #e0e0e0;
    overflow: visible; 
  }
  .logo{
    overflow: visible;
    position: relative;
    z-index: 2;
    height: 100px;
  }
  .logo img {
    height: 100%;
  }

  .nav.open {
    display: flex;
  }

  .nav ul {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .nav ul li {
    margin: 10px 0;
    text-align: center;
    border-bottom: 1px solid #e0e0e0;
    padding: 15px 20px;
  }

  .nav-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
  }

  /* .hamburger {
    display: block;
  } */

  .hamburger {
    display: block;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1000; /* Ensure it's above other elements */
    color: black; /* Make sure it has a visible color */
  }
  
  .hamburger svg {
    color: #000; /* Ensure the FontAwesome icon is visible */
  }

  .header-actions {
    display: none;
  }
  .header-top{
    display: none;
  }
  .loginn, .signuup{
    display: none;
  }
}
